import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./Dashboard.css";
import { Task } from "../../entities/Task";
import TaskDialog from "../../modules/taskDialog/TaskDialog";
import { Done } from "../../entities/Done";
import useTranslate from "../../internl/translate";
import DonesList from "../../modules/donesList/DonesList";
import notify from "../../elements/toast/Toast";
import showConfirmationDialog from "../../modules/confirmationDialog/ConfirmationDialog";
import { useNavigate, useParams } from "react-router-dom";
import tasksStorage from "../../dataLayer/tasksStorage";
import donesStorage from "../../dataLayer/donesStorage";
import { Group } from "../../entities/Group";
import groupsStorage from "../../dataLayer/groupsStorage";
import serverStorage from "../../dataLayer/serverStorage";
import Search from "../../elements/search/Search";
import userStorage from "../../dataLayer/userStorage";
import { User } from "../../entities/User";
import TaskCard from "./TaskCard";


interface DashboardProps {
	setPageTools: (elements: any) => void;
	setPageName: (element: JSX.Element | null) => void;
	requestPermission: (onFinish: () => void) => Promise<void>;
}


export default function Dashboard(props: DashboardProps) {
	const [ user, setUser ] = useState<User>();
	const { setPageTools, setPageName, requestPermission } = props;
	const [ taskList, setTaskList ] = useState<Task[]>([]);
	const [ filteredTasks, setFilteredTasks ] = useState<Task[]>([]);
	const [ filterValue, setFilterValue ] = useState<string>("");
	const [ filterState, setFilterState ] = useState<boolean>(false);
	const { taskId } = useParams();
	const [ task, setTask ] = useState<Task>();
	const TO_DO = useTranslate("to do");
	const navigate = useNavigate();
	const [ priorityMap, setPriorityMap ] = useState<Map<string, number>>(new Map());
	const [ averagePriority, setAveragePriority ] = useState<number>(1);
	const taskDialogRef = useRef(null);


	const updatePriorityMap = (taskId: string, prior: number) => {
		setPriorityMap((priorityMap) => {
			const newMap = new Map(priorityMap);
			newMap.set(taskId, prior);
			return newMap;
		});
	};


	useEffect(() => {
		setAveragePriority(() => {

			let prioritySum = 0;
			let prioritiesList: number[] = Array.from(priorityMap.values()).filter((item) => item >= 0);

			for (const prior of prioritiesList) {
				prioritySum += (prior);
			}

			return prioritySum / priorityMap.size;
		});

		setTaskList((tasks: Task[]) => {
			const newTasks: Task[] = tasks.sort((a: Task, b: Task) => {
				const aPriority = priorityMap.get(a.id || "");
				const bPriority = priorityMap.get(b.id || "");
				if (aPriority === undefined || bPriority === undefined) return 0;
				return bPriority - aPriority;
			});

			return newTasks;
		});

	}, [ priorityMap ]);


	useEffect(() => {

		if (taskId) {
			const existingTask = taskList.find((listTask: Task) => listTask.id === taskId);
			console.log(existingTask);

			if (existingTask) {
				setTask(existingTask);
			} else {
				if (!task) {
					setTask(tasksStorage.buildTask({
						id: taskId,
					}));
				}
			}

			document.body.style.overflow = "hidden";
		} else {
			setTask(undefined);
			document.body.style.overflow = "unset";
			const prMap = new Map(priorityMap);
			setPriorityMap(prMap);
		}
	}, [ taskId, taskList ]);


	useEffect(() => {
		if (!filterState) {
			setPageName(
				<h3 className={"pageName"}>
					Dashboard
				</h3>,
			);
		} else {
			setPageName(null);
		}

		return () => {
			setPageName(null);
		};
	}, [ setPageName, filterState ]);


	useEffect(() => {
		const getUser = (user: User) => {
			setUser(user);
		};

		userStorage.getUser(getUser, "dashboard");
	}, []);


	const getTask = async (task: Task) => {

		//console.log(task.name);

		const group: Group | undefined = groupsStorage.getGroup(task.group);
		if (group) {
			task.groupName = group?.name;
		} else {
			task.groupName = "personal";
		}

		task.priority = undefined;

		setTaskList((tasks) => {
			const newTasks = [ ...tasks ];
			const index = newTasks.findIndex((t) => t.id === task.id);

			if (index >= 0) {
				if (task.active) {
					// if task is active replace
					newTasks.splice(index, 1, task);
				} else {
					// if not remove it
					newTasks.splice(index, 1);
				}
			} else if (task.active) {
				newTasks.push(task);
			}

			return newTasks;
		});
	};

	useLayoutEffect(() => {
		tasksStorage.getTasks(getTask, "dashboard");
	}, []);


	useEffect(() => {

		setPageTools([
			<Search
				key="search"
				onClick={onSearchClick}
				onSearch={onSearch}
			/>,
			<button
				className="btn"
				key={"refresh"}
				onClick={
					() => {
						if (user) {
							serverStorage.init();
						} else {
							console.warn("No user");
						}
					}
				}
			>
				<div className="refreshButton">
					<i className="material-icons">refresh</i>
				</div>
			</button>,
			<button
				className="btn"
				key={"addButton"}
				onClick={
					() => {
						openTaskDialog(tasksStorage.buildTask({}));
					}
				}
			>
				<div className="addButton">
					<p>
						Add
					</p>
					<i className="material-icons">add</i>
				</div>
			</button>,
		]);

		return () => {
			setPageTools([ <div key="123"/> ]);
		};
	
	}, [ setPageTools, user ]);


	const onSearchClick = async (onFinish: () => void) => {
		setFilterState(true);
		onFinish();
	};


	const onSearch = (value: string) => {
		setFilterValue(value);
	};


	useLayoutEffect(() => {

		if (filterValue !== "") {
			const newFilteredTasks: Task[] = taskList.filter((item: Task) => {
				let match: boolean = false;

				for (const [ key, attributeValue ] of Object.entries(item)) {
					if (key !== "calculatePriority") {
						// eslint-disable-next-line no-new-wrappers
						if (new String(attributeValue).toLowerCase().includes(filterValue.toLowerCase())) {
							match = true;
							return true;
						}
					}
				};

				return match;
			});

			setFilteredTasks(newFilteredTasks);

		} else {
			setFilteredTasks(taskList);
		}

	}, [ taskList, filterValue ]);

	useEffect(() => {
		let removeDialog: () => void;
		if (user) {
			if (("Notification" in window)) {
				console.log(Notification.permission);
				const notificationsStatus = localStorage.getItem("notifications");
				if (Notification.permission === "default" && notificationsStatus !== "refused") {
					removeDialog = showConfirmationDialog({
						onConfirm: requestPermission,
						onClose: () => {
							localStorage.setItem("notifications", "refused");
						},
						text: "Activate notifications?",
					});
				};
			}
		}
		return () => {
			if (removeDialog) removeDialog();
		};
	}, [ user ]);


	const openTaskDialog = (task: Task) => {
		console.log("Navigate to: ", task.id);
		// setTask({ ...task });
		navigate(`${task.id}`);
	};

	const getGroupParticipantsEmails = async (groupId: string): Promise<string[]> => {

		const group: Group | undefined = groupsStorage.getGroup(groupId);

		console.log(group);

		if (group) {
			return group.participants ?? [];
		} else {
			return [];
		}
	};


	const doneTask = async (onFinish: any, target?: any) => {

		console.log(target);

		const task: Task = target as Task;

		if (!task) {
			notify("Done not saved!", 5);
			onFinish();
			return;
		}

		if (!task.recurring) {
			tasksStorage.deleteTask(task);
		}

		onFinish();

		const newDone: Done = donesStorage.buildDone({
			taskName: task.name,
			task: task.id || "",
			arbiters: [],
			author: user?.email || "",
			group: task.group,
			groupName: task.groupName,
		});

		await donesStorage.saveDone(newDone);

		getGroupParticipantsEmails(task.group).then(async (participantsEmails) => {

			console.log(participantsEmails);

			const arbitersEmails = participantsEmails.filter((email: string) =>
				email !== user?.email,
			);

			newDone.arbiters = arbitersEmails;
	
			await donesStorage.saveDone(newDone);

			// close task dialog if open
			if (taskId) {
				navigate("/dashboard");
			}
		});
	};

	const getColor = (taskPriority: number): string => {
		if (taskPriority < 0) {
			return "green";
		} else if (taskPriority >= (averagePriority * 2)) {
			return "red";
		}

		return "";
	};


	return (
		<div className={"page"} id="dashboard">
			<h4>
				{ TO_DO }
			</h4>
			{
				filteredTasks.length > 0 ?
					<div id={"taskList"}>
						{
							filteredTasks.map((task: Task) =>
								<TaskCard
									key={task.id}
									task={task}
									openTaskDialog={openTaskDialog}
									getColor={getColor}
									doneTask={doneTask}
									updatePriorityMap={updatePriorityMap}
								/>
							)
						}
					</div>					:
					<div>
						No tasks
					</div>
			}

			<h4>
				Done:
			</h4>

			<DonesList
				preview
			/>

			{
				task ?
					<TaskDialog
						task={task}
						setTask={setTask}
						doneTask={doneTask}
						ref={taskDialogRef}
					/> :
					null
			}
		</div>
	);

}
