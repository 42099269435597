import React, { useEffect, useState } from "react";
import "./SettingsPage.css";
import Button from "../../elements/button/Button";
import showConfirmationDialog from "../../modules/confirmationDialog/ConfirmationDialog";
import LanguageButton from "../../elements/language_button/LanguageButton";
import userStorage from "../../dataLayer/userStorage";
import { useNavigate } from "react-router-dom";
import { User } from "../../entities/User";


interface SettingsProps {
	setPageName: (element: JSX.Element) => void;
	requestPermission: (onFinish: () => void) => Promise<void>;
}


export default function Settings(props: SettingsProps) {
	
	const [ user, setUser ] = useState<User>();
	const { setPageName, requestPermission } = props;
	const [ nickName, setNickName ] = useState<string>("");
	const navigate = useNavigate();

	console.log(user);
	
	useEffect(() => {
		const getUser = (user: User) => {
			setUser(user);
		};

		userStorage.getUser(getUser, "settings");
	}, []);
	
	useEffect(() => {
		
		setPageName(
			<h3 className={"pageName"}>
				Settings
			</h3>,
		);
		
		return () => {

			setPageName(<div/>);
		
		};
		
	}, [ setPageName ]);

	useEffect(() => {
		setNickName(userStorage.getNickname() ?? "");
	}, []);
	
	
	async function saveNickName(event: any) {
		
		event.preventDefault();
		
		userStorage.setNickname(nickName);
	}

	async function onSignOut(onFinish: () => void) {
		// await signOut();
		userStorage.removeUser();
		onFinish();
	}

	async function signIn(onFinish: () => void) {
		navigate("/login");
		onFinish();
	}

	const onSave = async (onFinish: () => void) => {
		onFinish();
	};

	const onActivateNotifications = async (onFinish: () => void) => {
		showConfirmationDialog({
			onConfirm: requestPermission,
			onClose: onFinish,
			text: "Activate notifications?",
		});
	};
	
	
	return (
		<div className="page" id="settingsPage">
			
			<div>
				<form onSubmit={saveNickName} autoComplete="on">
				
					<div className="textField">
						<label htmlFor="nickName">
							<b>Nickname: </b>
						</label>
						<input
							type="text"
							placeholder="Enter nick name"
							name="nickName"
							required
							value={nickName}
							onChange={(e) => setNickName(e.target.value)}
						/>
					</div>

					<Button
						text={"Save"}
						type="submit"
						classes="confirmButton"
						onButtonClick={onSave} // TODO: Add the callback function
					/>
				</form>

				<div className="textField">
					<label>
						<b>Language:</b>
					</label>
					<LanguageButton
						styles={{
							color: "black",
						}}
					/>
				</div>

				<Button
					text={"Activate notifications"}
					styles={{ width: "200pt", marginTop: "50pt" }}
					onButtonClick={onActivateNotifications}
				/>
			</div>

			{
				user ?
					<Button
						text={"Sign out"}
						onButtonClick={onSignOut}
					/> :
					<Button
						text={"Sign in"}
						onButtonClick={signIn}
					/>
			}
		
		</div>
	);
}
