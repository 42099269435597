import React, { useEffect, useState } from "react";
import "./LoginPage.css";
import useTranslate from "../../internl/translate";
import { NavLink, useLocation, Location, useNavigate } from "react-router-dom";
import Button from "../../elements/button/Button";
import { User } from "../../entities/User";
import userStorage from "../../dataLayer/userStorage";
import Section from "../../elements/section/Section";
import Spinner from "../../elements/spinner/Spinner";
import serverStorage from "../../dataLayer/serverStorage";


interface LoginPageProps {
}

/**
 * Render app page element
 * @param {LoginPageProps} props for login page
 * @return {JSX.Element} Login page JSX element
 */
export default function LoginPage(props: LoginPageProps) {
	
	const [ user, setUser ] = useState<User | undefined>();
	const DASHBOARD = useTranslate("dashboard");
	const YOU_ARE_SIGNED = useTranslate("You are signed in as");
	const SIGNED_OUT = useTranslate("Sign out");
	const navigate = useNavigate();
	const location: Location = useLocation();
	const [ form, setForm ] = useState<User>({ email: "", pass: "" });
	// const [ userStatus, setUserStatus ] = useState<string>("idle");

	// @ts-ignore: Unreachable code error
	const from = location.state?.from?.pathname || "/";

	useEffect(() => {
		const getUser = (user: User | undefined) => {
			setUser(user);
			if (user?.status === "authenticated") {
				navigate(from);
			}
		};

		userStorage.getUser(getUser, "loginPage");
	}, []);
	
	const onSignOut = async () => {
		//signOut();
	};

	const signIn = async (onFinish: ()=>void): Promise<any> => {
		//setUserStatus("pending");
		form.status = "authenticating";
		console.log(form);
		await userStorage.saveUser(form);
		serverStorage.init();
		onFinish();
		return;
	};
	
	
	return (
		<div className={"page"} id={"loginPage"}>
			
			{user?.status !== "authenticating" ? (
				<div id={"loginPageContent"}>

					<Section>
						<div className="textField">
							<label htmlFor="email">
								<b>
								Email:
								</b>
							</label>
							<input
								name="email"
								type="email"
								value={form.email}
								onChange={(event) => {
									setForm((init) => {
										return {
											...init,
											email: event.target.value
										};
									});
								}}
							/>
						</div>
					</Section>

					<Section>
						<div className="textField">
							<label htmlFor="password">
								<b>
								Password:
								</b>
							</label>
							<input
								name="password"
								type="password"
								value={form.pass}
								onChange={(event) => {
									setForm((init) => {
										return {
											...init,
											pass: event.target.value || ""
										};
									});
								}}
							/>
						</div>
					</Section>

					{
						user?.status !== "authenticated" &&
							<Section>
								<Button
									text={"Sign in"}
									onButtonClick={signIn}
								/>
							</Section>
					}
				
					{
						user?.status === "authenticated" &&
						<NavLink to={"/"}>
							<Button
								styles={{ margin: 8 }}
								text={DASHBOARD}
								onButtonClick={async () => {}}
							/>
						</NavLink>
					}
				
					{
						user?.status === "authenticated" &&
						<div>
							{
								YOU_ARE_SIGNED.concat(" ") + (user?.displayName || user.email)
							}
						</div>
					}
				
					{
						user?.status === "authenticated" &&
						<Button
							onButtonClick={onSignOut}
							styles={{ margin: 8 }}
							text={SIGNED_OUT}
						/>
					}

					{
						user?.status === "rejected" &&
						<div>
							{
								"Incorrect password"
							}
						</div>
					}

				</div>) : <Spinner/>
			}
			
		</div>
	);

}
