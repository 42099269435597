import React, { useEffect, useState } from "react";
import scoresStorage from "../../dataLayer/scoreStorage";
import { Score } from "../../entities";


export enum IntervalEnum {
	// eslint-disable-next-line no-unused-vars
	DAY = "day",
	// eslint-disable-next-line no-unused-vars
	WEEK = "week",
	// eslint-disable-next-line no-unused-vars
	MONTH = "month"
}

const getStartTime = (interval: IntervalEnum): Date => {
	if (interval === IntervalEnum.DAY) {
		return new Date(new Date().setHours(0, 0, 0, 0));
	}

	if (interval === IntervalEnum.WEEK) {
		const date = new Date(new Date().setHours(0, 0, 0, 0));
		const diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
		return new Date(date.setDate(diff));
	}

	if (interval === IntervalEnum.MONTH) {
		return new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(0, 0, 0, 0));
	}

	return new Date();
};

interface TotalScoreProps {
  groupId: string;
	interval: IntervalEnum;
	email?: string;
}

export default function TotalScore(props: TotalScoreProps) {
	const { groupId, interval, email } = props;
	const [ scoreSum, setScoreSum ] = useState(0);
	const [ scores, setScores ] = useState<Score[]>([]);
	const startTime = getStartTime(interval);


	const getScores = (score: Score) => {

		// console.log("getScores: ", score);

		const filterByEmail = email ? score.doneAuthorEmail === email : true;

		if (score.groupId === groupId && new Date(score.doneDate) >= startTime && filterByEmail) {
		
			setScores((scores) => {
				const newScores = [ ...scores ];
				const index = newScores.findIndex((t) => t.id === score.id);

				if (index >= 0) {
					if (score.active) {
						newScores.splice(index, 1, score);
					} else {
						newScores.splice(index, 1);
					}
				} else if (score.active) {
					newScores.push(score);
				}

				return newScores;
			});
		}
	};


	useEffect(() => {
		let callerName = "TotalScore".concat(groupId).concat(interval);
		if (email) {
			callerName = callerName.concat(email);
		}
		scoresStorage.getScores(getScores, callerName);
	}, [ groupId, interval, email ]);
	

	useEffect(() => {

		setScoreSum(getTotalScore(scores));

	}, [ scores ]);

	const getTotalScore = (doneScores: Score[]): number => {
		let totalScore = 0;
		if (!doneScores || doneScores.length === 0) return totalScore;
		totalScore = doneScores.map((score) => score.points).reduce((a, b) => a + b, 0);
		return totalScore;
	};

	return (
		<div>
			<p>
				{`${scoreSum} points`}
			</p>
		</div>
	);
};
