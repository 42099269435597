import { v4 as uuidv4 } from "uuid";
import { Done } from "../entities/Done";
import objects from "../utils/objects";

const type = "dones";

const callBackCallers: Map<string, Function> = new Map();

function callAll(done: Done) {
	// console.log("Dones callbacks", done);
	callBackCallers.forEach((callBack) => {
		callBack(done);
	});
}

async function getDones({
	callback,
	limit,
	caller,
	taskId,
}: {
	callback: (done: Done | null) => void,
	caller?: string,
	limit?: number,
	taskId?: string,
}): Promise<void> {

	if (caller) callBackCallers.set(caller, callback);

	let dones: Done[] = JSON.parse(localStorage.getItem(type) || "[]");

	if (dones.length > 0) {

		if (taskId) {
			dones = dones.filter((done: Done) => done.task === taskId);
		}

		if (dones.length <= 0) {
			callback(null);
			return;
		}

		dones = dones.sort((a: Done, b: Done) => {
			if (!a.date || !b.date) return 1;
			const aDate = new Date(a.date);
			const bDate = new Date(b.date);
			return aDate < bDate ? 1 : -1;
		});

		let activeDonesCount = 0;

		for (const [ , item ] of dones.entries()) {
			if (limit && activeDonesCount >= limit) {
				break;
			}
			callback(item);
			if (item.active) {
				activeDonesCount++;
			}
		};
	}
}

function getDonesByTask(taskId: string) {

	let dones: Done[] = JSON.parse(localStorage.getItem(type) || "[]");

	dones = dones.filter((done: Done) => done.task === taskId);

	dones = dones.sort((a: Done, b: Done) => {
		if (!a.date || !b.date) return 1;
		const aDate = new Date(a.date);
		const bDate = new Date(b.date);
		return aDate < bDate ? 1 : -1;
	});

	return dones;
}

async function saveDone(
	done: Done,
) {
	//console.log("Save done: ", done);

	let dones: Done[] = JSON.parse(localStorage.getItem(type) || "[]");

	// console.log(dones);
	
	let echo = false;

	const oldTask = dones.find((t) => t.id === done.id);

	if (oldTask) {
		// console.log(objects.deepEqual(oldTask, done));
		if (!objects.deepEqual(oldTask, done)) {
			dones = dones.map((t) => {
				return t.id === done.id ? done : t;
			});
		} else {
			echo = true;
		}
	} else {
		dones.push(done);
	}

	localStorage.setItem(type, JSON.stringify(dones));

	if (!echo) {
		callAll(done);
	}
}

async function deleteDone(done: Done): Promise<boolean> {
	const dones: Done[] = JSON.parse(localStorage.getItem(type) || "[]");
	// const filteredDones = dones.filter((item) => item.id !== done.id);
	localStorage.setItem(type, JSON.stringify(dones));
	done.active = false;
	done.lastDateModified = new Date().toISOString();
	callAll(done);
	return true;
}

function buildDone({
	...args
}) {
	const newDone: Done = {
		type: "done",
		date: new Date().toISOString(),
		task: "",
		id: uuidv4(),
		active: true,
		author: "",
		group: "",
		...args,
		lastDateModified: new Date().toISOString(),
	};

	return newDone;
}

async function updateDone(
	doneId: string,
	args: object,
): Promise<boolean> {
	const dones: Done[] = JSON.parse(localStorage.getItem(type) || "[]");
	const done: Done | undefined = dones.find((item) => item.id === doneId);

	if (!done) {
		return false;
	}

	const updatedDone: Done = {
		...done,
		...args,
		lastDateModified: new Date().toISOString(),
	};

	dones.map((item) => {
		if (item.id === doneId) {
			return updatedDone;
		} else {
			return item;
		}
	});

	localStorage.setItem(type, JSON.stringify(dones));
	callAll(updatedDone);

	return true;
}


/* async function getDonesByTask({
	callback,
	caller,
}: {
	callback: (done: Done) => void,
	caller?: string,
}): Promise<void> {

	if (caller) callBackCallers.set(caller, callback);

	const dones: Done[] = JSON.parse(localStorage.getItem(type) || "[]");

	if (dones?.length > 0) {

		dones.sort((a: Done, b: Done) => {
			if (!a.date || !b.date) return 1;
			const aDate = new Date(a.date);
			const bDate = new Date(b.date);
			return aDate < bDate ? 1 : -1;
		});

		dones.forEach((done) => callback(done));
	}
} */


function getDonesForScoring(): Done[] {
	return [];
}

const donesStorage = {
	getDones,
	saveDone,
	buildDone,
	deleteDone,
	updateDone,
	getDonesForScoring,
	getDonesByTask,
};

export default donesStorage;
