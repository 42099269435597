import React, { useEffect, useState } from "react";
import { Score } from "../../entities/Score";
import scoresStorage from "../../dataLayer/scoreStorage";

interface AverageScoreProps {
  doneId: string;
}

export default function AverageScore(props: AverageScoreProps) {
	const { doneId } = props;
	const [ score, setScore ] = useState(0);

	useEffect(() => {

		const getScore = (doneId: string): number => {

			let score: number = 0;
			/* const scoresQuery: Query<DocumentData> = query(collection(db, "scores"), where("doneId", "==", doneId));
  
			onSnapshot(scoresQuery, (scoresSnapshot) => {
				const scores: Score[] = [];
				scoresSnapshot.forEach((doc) => {
					// console.log(score.data());
					const data = doc.data();
					const score = scoresStorage.buildScore({
						id: data.id,
						authorEmail: data.email,
						points: data.points,
						date: data.date,
						doneId: data.doneId,
						groupId: data.groupId,
						doneAuthorEmail: data.doneAuthorEmail,
						doneDate: data.date,
						taskName: data.taskName,
					});
					scores.push(score);
				});
				score = getAverageScore(scores);

				setScore(score);
			}); */
  
			return score;
		};

		getScore(doneId);

	}, [ doneId ]);

	const getAverageScore = (doneScores: Score[]): number => {
		let averageScore = 0;
		if (!doneScores || !doneScores.length || doneScores.length === 0) return averageScore;
		averageScore = doneScores.map((score) => score.points || 0).reduce((a, b) => a + b, 0) / doneScores.length;
		return averageScore;
	};

	return (
		<div>
			<p>
				{`${score} points`}
			</p>
		</div>
	);
};
