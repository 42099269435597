import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./PrivateRoute";
import LoginPage from "./pages/login/LoginPage";
import Dashboard from "./pages/dashboard/Dashboard";
import GroupsPage from "./pages/groups/GroupsPage";
import Settings from "./pages/settings/SettingsPage";
import { User } from "./entities/User";
import ScoringPage from "./pages/scoring/ScoringPage";
import Toolbar from "./elements/toolbar/Toolbar";
import { LangContext } from "./context/LangContext";
import DonesPage from "./pages/dones/DonesPage";
import getMessagingToken from "./dataLayer/getToken";
import userStorage from "./dataLayer/userStorage";
import serverStorage from "./dataLayer/serverStorage";


/**
 * Render app root element
 * @return {React.JSX.Element} App root directory
 */
function App(): React.JSX.Element {

	const [ pageTools, setPageTools ] = useState<JSX.Element[]>([]);
	const [ pageName, setPageName ] = useState<JSX.Element | null>(<div/>);
	const [ lang, setLang ] = useState<string>(localStorage.getItem("lang") || "EN");


	useEffect(() => {
		console.log("App init");
		serverStorage.init();
	}, []);

	useEffect(() => {

		const onVisibilityChange = () => {
			if (!document.hidden) {
				// refreshToken();
				serverStorage.init();
			}
		};

		document.addEventListener("visibilitychange", onVisibilityChange);

		return () => {
			document.removeEventListener("visibilitychange", onVisibilityChange);
		};
	}, []);
	

	const requestPermission = async (onFinish: () => void): Promise<void> => {

		console.log("Requesting permission...");

		onFinish();

		/* if (user?.status === "authenticated") {
			console.error("User is not defined");
			return;
		} */

		if (!("Notification" in window)) {
			console.log("Notifications not supported");
			return;
		}

		await Notification.requestPermission((permission) => {
			console.log("Notification permission is: ", permission);

			if ("serviceWorker" in navigator) {
				navigator.serviceWorker.register("firebase-messaging-sw.js")
					.then((registration) => {
						console.dir(registration);
						userStorage.getUser((user: User) => {
							if (user) {
								getMessagingToken(user, registration);
							}
						});
						
					}).catch((err) => {
						console.error(err);
						return;
					});
			} else {
				console.log("Service Worker not supported");
			}

		});

		return;
	};
	
	
	return (
		<div className="App">

			<LangContext.Provider value={{ lang, setLang }}>
				
				<Router>
		
					<Toolbar
						pageName={pageName}
						pageTools={pageTools}
					/>
						
					<div className={"appContent"}>
					
						<Routes>

							<Route
								path="/"
								element={ <Navigate to="/dashboard" /> }
							/>
								
							<Route
								path="/login"
								element={
									<LoginPage
									/>
								}
							/>
								
							<Route
								path="/dashboard"
								element={
									<Dashboard
										setPageTools={setPageTools}
										setPageName={setPageName}
										requestPermission={requestPermission}
									/>
								}
							>
								<Route
									path=":taskId"
									element={
										<Dashboard
											setPageTools={setPageTools}
											setPageName={setPageName}
											requestPermission={requestPermission}
										/>
									}
								/>
							</Route>

							<Route
								path="/dones"
								element={
									<DonesPage
										setPageTools={setPageTools}
										setPageName={setPageName}
									/>
								}
							/>

							<Route
								path="/groups"
								element={
									<PrivateRoute>
										<GroupsPage
											setPageTools={setPageTools}
											setPageName={setPageName}
										/>
									</PrivateRoute>
								}
							>
								<Route
									path=":groupId"
									element={
										<PrivateRoute>
											<GroupsPage
												setPageTools={setPageTools}
												setPageName={setPageName}
											/>
										</PrivateRoute>
									}
								/>
							</Route>

							<Route
								path="/scoring"
								element={
									<PrivateRoute>
										<ScoringPage
											setPageTools={setPageTools}
											setPageName={setPageName}
										/>
									</PrivateRoute>
								}
							/>

							<Route
								path="/settings"
								element={
									<Settings
										setPageName={setPageName}
										requestPermission={requestPermission}
									/>
								}
							/>
								
						</Routes>
							
					</div>
				</Router>
			</LangContext.Provider>
		</div>
	);

}

export default App;

