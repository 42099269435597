import { v4 as uuidv4 } from "uuid";
import { Score } from "../entities/Score";
import objects from "../utils/objects";

const type = "scores";

const callBackCallers: Map<string, Function> = new Map();

function callAll(score: Score) {
	console.log("Scores callbacks", score);
	callBackCallers.forEach((callBackFun) => {
		callBackFun(score);
	});
}

async function getScores(
	callback: (score: Score) => void,
	caller?: string,
) {
	if (caller) callBackCallers.set(caller, callback);
	const scores: Score[] = JSON.parse(localStorage.getItem(type) || "[]");
	if (scores?.length > 0) {
		scores.forEach((item) => {
			callback(item);
		});
	}
}

async function saveScore(
	score: Score,
) {

	// console.log("saveScore: ", score);

	let scores: Score[] = JSON.parse(localStorage.getItem(type) || "[]");

	let echo = false;

	const oldScore = scores.find((t) => t.id === score.id);

	if (oldScore) {
		// console.log(objects.deepEqual(oldTask, done));
		if (!objects.deepEqual(oldScore, score)) {
			scores = scores.map((t) => {
				return t.id === score.id ? score : t;
			});
		} else {
			echo = true;
		}
	} else {
		scores.push(score);
	}

	localStorage.setItem(type, JSON.stringify(scores));

	if (!echo) {
		callAll(score);
	}
}

async function deleteScore(score: Score): Promise<boolean> {
	const scores: Score[] = JSON.parse(localStorage.getItem(type) || "[]");
	const filteredScores = scores.filter((item) => item.id !== score.id);
	localStorage.setItem(type, JSON.stringify(filteredScores));
	score.active = false;
	callAll(score);
	return true;
}

function buildScore({ ...args }: any) {
	const newScore: Score = {
		type: "score",
		id: uuidv4(),
		active: true,
		authorEmail: "",
		points: 0,
		date: new Date().toISOString(),
		doneId: "",
		groupId: "",
		doneAuthorEmail: "",
		doneDate: "",
		taskName: "",
		...args,
	};

	return newScore;
}

const scoresStorage = {
	getScores,
	saveScore,
	buildScore,
	deleteScore,
};

export default scoresStorage;
